import React from 'react';

const FireRatingLegend = () => {
  const ratings = [
    {
      level: 1,
      description: "This set, using average prices, costs less than the average price of the card",
      flames: 1,
      color: "text-orange-500"
    },
    {
      level: 2,
      description: "This set, using average prices, costs less than both the average price and the cheapest buyout",
      flames: 2,
      color: "text-orange-500"
    },
    {
      level: 3,
      description: "The average set cost is lower than current market prices and efficient to build",
      flames: 3,
      color: "text-orange-500"
    },
    {
      level: 4,
      description: "Profitable Set - Has clear profit potential when built and sold at market price",
      flames: 4,
      color: "text-orange-500"
    },
    {
      level: 5,
      description: "Profitable + Cheaper than Buyout - Higher profit potential due to efficient building cost",
      flames: 5,
      color: "text-orange-500"
    },
    {
      level: 6,
      description: "High Profit Potential - Q3+ possible with strong market demand",
      flames: 6,
      color: "text-orange-500"  // Changed to orange to match the image
    }
  ];

  const renderFlames = (numFlames, color) => {
    return [...Array(numFlames)].map((_, index) => (
      <span 
        key={index} 
        className={`${color} text-lg sm:text-xl`}
      >
        🔥
      </span>
    ));
  };

  return (
    <div className="bg-white shadow p-3 sm:p-4 rounded-lg mb-4">
      <h2 className="text-base sm:text-lg font-bold mb-2 sm:mb-4">Sets on Fire - Rating System</h2>
      <div className="space-y-3">
        {ratings.map((rating) => (
          <div key={rating.level} className="flex flex-col sm:flex-row sm:items-start space-y-1 sm:space-y-0 sm:space-x-2">
            <div className="flex items-center sm:items-start space-x-2 sm:min-w-[120px]">
              <div className="font-medium text-sm sm:text-base min-w-[65px]">
                Level {rating.level} Set
              </div>
              <div className="flex space-x-0.5 sm:space-x-1">
                {renderFlames(rating.flames, rating.color)}
              </div>
            </div>
            <div className="text-xs sm:text-sm text-gray-600 pl-[65px] sm:pl-0">
              {rating.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FireRatingLegend;