import React from 'react';

const Header = () => {
  return (
    <header className="bg-black py-4 flex justify-between items-center px-6">
      {/* Left Section: Sets Tool */}
      <div className="text-xl font-bold" style={{ color: '#ff0000' }}> {/* MUT Exchange red */}
        Sets Tool
      </div>

      {/* Center Section: MUT Exchange Styled Logo */}
      <div className="text-center">
        <a
          href="https://mut.exchange/dashboard"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white text-2xl font-bold tracking-wide uppercase hover:opacity-80"
          style={{
            textShadow: '0px 2px 4px rgba(255, 255, 255, 0.5)', // Subtle glowing effect
            fontFamily: "'Roboto', sans-serif", // Clean font
          }}
        >
          MUT Exchange
        </a>
        <p
          className="text-red-500 text-sm font-semibold"
          style={{
            letterSpacing: '0.05em', // Spacing for "THE MUT MARKETPLACE"
          }}
        >
          THE MUT MARKETPLACE
        </p>
      </div>

      {/* Right Section: PlayStation 5 */}
      <div className="text-xl font-bold" style={{ color: '#ff0000' }}> {/* MUT Exchange red */}
        PlayStation 5
      </div>
    </header>
  );
};

export default Header;
