import React from 'react';
import { Flame } from 'lucide-react';

const SetDetails = ({ set }) => {
  // Extract overall rating from the set_label
  const overallRating = set.set_label && set.set_label.length >= 2 ? `${set.set_label.slice(0, 2)} OVR` : '';

  return (
    <div className="border p-6 rounded-lg mb-6 bg-white">
      <div className="flex justify-between items-start">
        <div>
          <h2 className="text-xl font-bold">
            {set.card_name} - {set.program_description} {overallRating && `- ${overallRating}`}
          </h2>
          <div className="text-lg mb-2">{set.set_label}</div>
          <div className="flex mt-1">
            {[...Array(3)].map((_, i) => (
              <Flame key={i} className="w-5 h-5 text-orange-500" />
            ))}
          </div>
        </div>
        <div className="bg-blue-100 px-3 py-1 rounded text-center">
          <div className="text-sm font-semibold">24h Sales</div>
          <div>{set.amountinhours24}</div>
        </div>
      </div>

      <div className="mt-4 space-y-1">
        <div>Buyout: {parseInt(set.cheapest_buyout_price).toLocaleString()}</div>
        <div>Q2: {parseInt(set.q2_price).toLocaleString()}</div>
        <div>Q3: {parseInt(set.q3_price).toLocaleString()}</div>
      </div>
    </div>
  );
};

export default SetDetails;

