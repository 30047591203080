import React from 'react';

const TargetBuyPrices = ({ set }) => {
  const getTargetPrices = () => {
    const parts = [];
    const setNotes = set[`set_notes`];

    // Extract and split parts from set_notes
    const notesParts = setNotes
      ? setNotes.split('=').pop().trim().split('&').map((note) => note.trim())
      : [];

    // Iterate through each part and calculate target prices dynamically
    for (let i = 1; i <= notesParts.length; i++) {
      const quantity = set[`part${i}_quantity`];
      const q2Subtotal = set[`part${i}_q2_subtotal`]; // Subtotal for the part

      // Calculate the target price per card
      const targetPrice = q2Subtotal && quantity ? Math.floor(q2Subtotal / quantity) : null;

      if (quantity && quantity > 0 && targetPrice) {
        parts.push(
          <div key={i}>
            <span>{notesParts[i - 1]}:</span>{' '}
            <span>Buy at {targetPrice.toLocaleString()} coins or below</span>
          </div>
        );
      }
    }

    return parts;
  };

  return (
    <div className="bg-blue-50 p-4 rounded-lg mt-4">
      <div className="font-semibold text-lg mb-3">Target Buy Prices:</div>
      <div className="space-y-2">
        {getTargetPrices()}
      </div>
    </div>
  );
};

export default TargetBuyPrices;
