import React from 'react';

const ProfitCalculation = ({ set }) => {
  const q2Price = parseInt(set.q2_price); // Q2 price
  const setTotal = parseInt(set.set_total_q2); // Total set cost
  const tax = Math.floor(q2Price * 0.1); // Auction House Tax (10%)
  const discountedPrice = Math.floor(q2Price * 0.9); // 90% of Q2 price
  const netProfit = q2Price - tax - setTotal; // Original profit calculation
  const altProfit = discountedPrice - setTotal; // Alternate profit using 90% of Q2 price

  return (
    <div className="bg-green-50 p-4 rounded-lg mb-4">
      <div className="font-semibold text-lg mb-3">Profit Calculation:</div>
      <div className="grid grid-cols-2 gap-2">
        <div>Total Set Cost:</div>
        <div className="text-right">-{setTotal.toLocaleString()} coins</div>
        <div>Q2 Selling Price:</div>
        <div className="text-right">{q2Price.toLocaleString()} coins</div>
        <div>AH Tax (10%):</div>
        <div className="text-right">-{tax.toLocaleString()} coins</div>
        <div className="font-bold text-lg pt-2 border-t">Net Profit:</div>
        <div className="font-bold text-lg pt-2 border-t text-green-600 text-right">
          {netProfit.toLocaleString()} coins
        </div>
      </div>

      {/* New math breakdown section */}
      <div className="mt-4 bg-gray-100 p-4 rounded-lg">
        <div className="font-semibold text-lg mb-2">Alternate Profit Breakdown:</div>
        <div className="grid grid-cols-2 gap-2">
          <div>Q2 Price × 90%:</div>
          <div className="text-right">{q2Price} × 0.9 = {discountedPrice.toLocaleString()} coins</div>
          <div>Discounted Price - Total Set Cost:</div>
          <div className="text-right">
            {discountedPrice.toLocaleString()} - {setTotal.toLocaleString()} = {altProfit.toLocaleString()} coins
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfitCalculation;


