import React from 'react';

const StrategyAnalysis = ({ set }) => {
  // Calculate Wishlist Price (10-15% below Q2)
  const wishlistPrice = Math.floor(parseInt(set.q2_price) * 0.9);

  // Define thresholds
  const setCheapestBuyout = parseInt(set.set_total_cheapest); // Total of cheapest buyouts for the set
  const setQ2Cost = parseInt(set.set_total_q2); // Q2 price to complete the set
  const cheapestBuyoutPrice = parseInt(set.cheapest_buyout_price); // Current cheapest buyout price
  const q2Price = parseInt(set.q2_price); // Current Q2 price

  // Conditions
  const noBrainerToDoSet =
    setCheapestBuyout <= wishlistPrice &&
    setCheapestBuyout < cheapestBuyoutPrice &&
    setCheapestBuyout < q2Price;

  const recommendWishlist =
    wishlistPrice < cheapestBuyoutPrice &&
    wishlistPrice < q2Price &&
    wishlistPrice < setCheapestBuyout &&
    wishlistPrice < setQ2Cost * 0.9;

  const profitOpportunity =
    q2Price > setQ2Cost &&
    q2Price - setQ2Cost > cheapestBuyoutPrice * 0.1; // Profitable margin > 10% of cheapest buyout

  const savingsOpportunity =
    cheapestBuyoutPrice > setCheapestBuyout &&
    setCheapestBuyout < q2Price &&
    setCheapestBuyout < wishlistPrice;

  return (
    <div className="bg-blue-50 p-6 rounded-lg mt-4 space-y-6">
      <div className="font-semibold text-xl mb-4">Strategy Analysis</div>

      {/* If Building For Your Team */}
      <div className="bg-blue-100 p-4 rounded-lg">
        <div className="font-semibold text-lg">If Building For Your Team:</div>
        <p>
          Use our target prices to build gradually.{' '}
          {savingsOpportunity
            ? `Potential savings of ${(cheapestBuyoutPrice - setCheapestBuyout).toLocaleString()} coins.`
            : 'Complete the set for optimal savings.'}
        </p>
      </div>

      {/* If Building For Profit */}
      <div className="bg-green-100 p-4 rounded-lg">
        <div className="font-semibold text-lg">If Building For Profit:</div>
        <p>
          {profitOpportunity
            ? `Target profit: ${(q2Price - setQ2Cost - q2Price * 0.1).toLocaleString()} coins after tax.`
            : 'No significant profit opportunities at the moment. Consider monitoring wishlist prices.'}
        </p>
      </div>

      {/* If Trading Up Lesser Value Cards */}
      <div className="bg-purple-100 p-4 rounded-lg">
        <div className="font-semibold text-lg">If Trading Up Lesser Value Cards:</div>
        <p>
          {savingsOpportunity
            ? 'Good opportunity to convert lower-demand cards.'
            : 'No significant upgrade opportunities available at the moment.'}
        </p>
        <ul className="text-sm list-disc ml-4">
          <li>Set cost efficient vs. buying outright</li>
          <li>
            Recent sales indicate{' '}
            <strong>{set.amountinhours24 || 0} sales in last 24h</strong> - good liquidity
          </li>
          <li>Use our target prices to maximize value</li>
        </ul>
      </div>

      {/* Bottom Line Recommendation */}
      <div className="bg-yellow-100 p-4 rounded-lg">
        <div className="font-semibold text-lg">Bottom Line Recommendation:</div>
        <p>
          {noBrainerToDoSet
            ? 'Do the set now! This is a no-brainer due to significant savings and profitability.'
            : recommendWishlist
            ? `Consider adding the card to your wishlist at ${wishlistPrice.toLocaleString()} coins.`
            : 'Monitor the market for better opportunities.'}
        </p>
      </div>
    </div>
  );
};

export default StrategyAnalysis;
