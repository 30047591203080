import React, { useState, useEffect } from 'react';
import './App.css';
import MaddenAnalysis from './components/MaddenAnalysis';
function App() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://mutking.com/api/set-construction-results?limit=1000&offset=0')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setData(data);
        console.log('Data received:', data);
      })
      .catch(error => {
        setError(error.message);
        console.error('Error fetching data:', error);
      });
  }, []);

  if (error) return <div>Error: {error}</div>;
  if (!data) return <div>Loading...</div>;

  return (
    <div className="App">
     <MaddenAnalysis/>
    </div>
  );
}

export default App;
