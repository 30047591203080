import React from 'react';

const WishlistVsSetsVsAuction = ({ set }) => {
  // Calculate Wishlist savings (Q2 * 0.9)
  const wishlistPrice = Math.floor(parseInt(set.q2_price) * 0.9);

  // Generic function to calculate total prices and collect card details
  const getPriceDetails = (cardsKeyPrefix, pricesKeyPrefix) => {
    let total = 0;
    const details = [];

    for (let i = 1; i <= 7; i++) { // Assuming up to 7 parts
      const cards = set[`${cardsKeyPrefix}${i > 1 ? `_pt${i}` : ''}`];
      const prices = set[`${pricesKeyPrefix}${i > 1 ? `_pt${i}` : ''}`];

      if (Array.isArray(cards) && Array.isArray(prices)) {
        cards.forEach((card, index) => {
          const price = parseInt(prices[index] || 0);
          total += price;
          details.push({ card, price });
        });
      }
    }

    return { total, details };
  };

  // Get details for Q2 prices
  const { total: q2Total, details: q2Details } = getPriceDetails(
    'cards_used_q2',
    'prices_used_q2'
  );

  // Get details for Cheapest Buyouts
  const { total: cheapestBuyoutTotal, details: cheapestBuyoutDetails } = getPriceDetails(
    'cards_used_cheapest_buyout',
    'prices_used_cheapest_buyout'
  );

  return (
    <div className="bg-purple-50 p-6 rounded-lg mt-4">
      <div className="font-semibold text-xl mb-4">Wishlist vs Sets vs Auction</div>

      {/* Auction Section */}
      <div className="mb-6">
        <div className="font-semibold text-lg mb-2">Auction:</div>
        <div className="flex justify-between">
          <span>Q2 Price:</span>
          <span>{parseInt(set.q2_price).toLocaleString()} coins</span>
        </div>
        <div className="flex justify-between">
          <span>Current Cheapest Buyout:</span>
          <span>{parseInt(set.cheapest_buyout_price).toLocaleString()} coins</span>
        </div>
      </div>

      {/* Set Costs Section */}
      <div className="mb-6">
        <div className="font-semibold text-lg mb-2">Set Total (Q2 Prices):</div>
        <div className="space-y-2">
          {q2Details.map((detail, index) => (
            <div key={index} className="flex justify-between text-sm">
              <span>{detail.card}:</span>
              <span>{detail.price.toLocaleString()} coins</span>
            </div>
          ))}
          <div className="flex justify-between font-bold mt-2">
            <span>Total:</span>
            <span>{q2Total.toLocaleString()} coins</span>
          </div>
        </div>

        <div className="font-semibold mt-4 mb-2">Set Total (Cheapest Buyouts):</div>
        <p className="text-sm text-gray-600 italic mb-2">
          Note: These prices could be up to 10 minutes old.
        </p>
        <div className="space-y-2">
          {cheapestBuyoutDetails.map((detail, index) => (
            <div key={index} className="flex justify-between text-sm">
              <span>{detail.card}:</span>
              <span>{detail.price.toLocaleString()} coins</span>
            </div>
          ))}
          <div className="flex justify-between font-bold mt-2">
            <span>Total:</span>
            <span>{cheapestBuyoutTotal.toLocaleString()} coins</span>
          </div>
        </div>
      </div>

      {/* Wishlist Section */}
      <div className="mb-6">
        <div className="font-semibold text-lg mb-2">Wishlist:</div>
        <div className="flex justify-between">
          <span>Q2 × 0.9 (Wishlist Savings):</span>
          <span>{wishlistPrice.toLocaleString()} coins</span>
        </div>
        <p className="text-sm text-gray-600 mt-2">
          The wishlist provides a solid 10% saving on set cards. We use this to base our analysis.
        </p>
      </div>
    </div>
  );
};

export default WishlistVsSetsVsAuction;
