import React from "react";

const Summary = ({
  totalSales,
  totalCards,
  setsWithQ2LessThanQ2Price,
  profitableSets,
  resultsFound, // Receive the resultsFound prop
}) => {
  return (
    <div className="bg-white shadow p-4 rounded-lg mb-4">
      <h2 className="text-lg font-bold mb-4">Summary</h2>
      <ul className="list-disc pl-5 space-y-2">
        <li>Total Sales (Last 24h): {totalSales}</li>
        <li>Total Cards in List: {totalCards}</li>
        <li>Sets with Q2 Price &lt; Q2 Card Price: {setsWithQ2LessThanQ2Price}</li> {/* Fixed here */}
        <li>Profitable Sets: {profitableSets}</li>
        <li>Results Found: {resultsFound}</li>
      </ul>
    </div>
  );
};

export default Summary;
