import React, { useEffect, useState } from "react";
import Header from "./Header";
import SetDetails from "./setAnalysis/SetDetails";
import SetRequirements from "./setAnalysis/SetRequirements";
import ProfitCalculation from "./setAnalysis/ProfitCalculation";
import TargetBuyPrices from "./setAnalysis/TargetBuyPrices";
import WishlistVsSetsVsAuction from "./setAnalysis/WishlistVsSetsVsAuction";
import StrategyAnalysis from "./setAnalysis/StrategyAnalysis";
import SetsOnFire from "./setAnalysis/SetsOnFire";
import Summary from "./Summary";
import FireRatingLegend from './setAnalysis/FireRatingLegend';

const MaddenAnalysis = () => {
  const [sets, setSets] = useState([]);
  const [filteredSets, setFilteredSets] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [totalCards, setTotalCards] = useState(0);
  const [setsWithQ2LessThanQ2Price, setSetsWithQ2LessThanQ2Price] = useState(0);
  const [profitableSets, setProfitableSets] = useState(0);
  const [bestTrainingSets, setBestTrainingSets] = useState([]);
  const [filters, setFilters] = useState({
    name: "",
    program: "All Programs",
    minOverall: 75,
    maxOverall: 99,
  });

  const trainingValues = {
    70: 1,
    71: 2,
    72: 3,
    73: 4,
    74: 7,
    75: 11,
    76: 18,
    77: 28,
    78: 44,
    79: 69,
    80: 110,
    81: 170,
    82: 270,
    83: 430,
    84: 680,
    85: 1080,
    86: 1700,
    87: 2700,
    88: 4200,
    89: 6700,
    90: 10600,
    91: 16700,
    92: 26000,
    93: 42000,
    94: null, // No value for 94 as per user input
    95: 100000,
  };

  const fetchSets = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://mutking.com/api/set-construction-results?limit=1000&offset=0
`);
      const data = await response.json();
  
      // Filter out "77 G. Olsen - Legends" explicitly
      const filteredData = data.filter(
        (set) =>
          set.card_name !== "G. Olsen" ||
          set.program_description !== "Legends" ||
          !set.set_label.startsWith("77")
      );
  
      // Calculate Summary Statistics
      const totalSales = filteredData.reduce(
        (sum, set) => sum + (parseInt(set.amountinhours24 || 0, 10)),
        0
      );
      const totalCards = filteredData.length;
      const setsWithQ2LessThanQ2Price = filteredData.filter(
        (set) => set.set_total_q2 < set.q2_price
      ).length;
      const profitableSets = filteredData.filter((set) => set.potential_profit > 0).length;

      // Calculate Best Sets for Training
      const setsWithTraining = filteredData.map((set) => {
        const overall = parseInt(set.set_label.split(" ")[0], 10);
        const trainingValue = trainingValues[overall];
        if (trainingValue) {
          const coinsPerTraining = set.set_total_q2 / trainingValue;
          return { ...set, coinsPerTraining };
        }
        return null;
      }).filter(set => set !== null);

      const bestTrainingSets = setsWithTraining
        .sort((a, b) => a.coinsPerTraining - b.coinsPerTraining)
        .slice(0, 5);
  
      // Get unique programs
      const uniquePrograms = [
        "All Programs",
        ...new Set(filteredData.map((set) => set.program_description)),
      ];
  
      setSets(filteredData);
      setFilteredSets(filteredData);
      setPrograms(uniquePrograms);
      setLastUpdate(new Date());
  
      // Update Summary Stats
      setTotalSales(totalSales);
      setTotalCards(totalCards);
      setSetsWithQ2LessThanQ2Price(setsWithQ2LessThanQ2Price);
      setProfitableSets(profitableSets);
      setBestTrainingSets(bestTrainingSets);
    } catch (error) {
      console.error("Error fetching sets:", error);
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = () => {
    let filtered = [...sets];

    // Filter by name
    if (filters.name) {
      filtered = filtered.filter((set) =>
        set.card_name.toLowerCase().includes(filters.name.toLowerCase())
      );
    }

    // Filter by program
    if (filters.program !== "All Programs") {
      filtered = filtered.filter((set) => set.program_description === filters.program);
    }

    // Filter by overall
    filtered = filtered.filter((set) => {
      const overall = parseInt(set.set_label.split(" ")[0], 10);
      return overall >= filters.minOverall && overall <= filters.maxOverall;
    });

    setFilteredSets(filtered);
  };

  const handleQuickFilter = (filterType) => {
    let sortedSets = [...sets];
    switch (filterType) {
      case "Most Profitable":
        sortedSets = sortedSets.sort((a, b) => b.potential_profit - a.potential_profit);
        break;
      case "Highest Sales":
        sortedSets = sortedSets.sort((a, b) => b.amountinhours24 - a.amountinhours24);
        break;
      case "Highest Overall":
        sortedSets = sortedSets.sort(
          (a, b) => parseInt(b.set_label.split(" ")[0], 10) - parseInt(a.set_label.split(" ")[0], 10)
        );
        break;
      case "Highest Q2":
        sortedSets = sortedSets.sort((a, b) => b.q2_price - a.q2_price);
        break;
      default:
        break;
    }
    setFilteredSets(sortedSets);
  };

  const clearFilters = () => {
    setFilters({
      name: "",
      program: "All Programs",
      minOverall: 75,
      maxOverall: 99,
    });
    fetchSets(); // Refresh the data to clear filters
  };

  useEffect(() => {
    fetchSets();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filters]);

  return (
    <div className="w-full max-w-7xl mx-auto p-4">
      <Header />

      <Summary
        totalSales={totalSales}
        totalCards={totalCards}
        setsWithQ2LessThanQ2Price={setsWithQ2LessThanQ2Price}
        profitableSets={profitableSets}
        resultsFound={filteredSets.length}
      />

      <FireRatingLegend />

      <div className="bg-white shadow p-4 rounded-lg mb-4">
        <h2 className="text-lg font-bold mb-4">Best Sets for Training</h2>
        <ul className="list-disc pl-5">
          {bestTrainingSets.map((set, index) => (
            <li key={index}>
              {set.card_name} ({set.set_label}): {set.coinsPerTraining.toFixed(2)} coins per training (Training Value: {trainingValues[parseInt(set.set_label.split(" ")[0], 10)]}, Set Total Q2 Cost: {set.set_total_q2})
            </li>
          ))}
        </ul>
      </div>

      {/* Filters Section */}
      <div className="bg-white shadow p-4 rounded-lg mb-4">
        <h2 className="text-lg font-bold mb-4">Filters</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <input
            type="text"
            placeholder="Search by Player Name"
            value={filters.name}
            onChange={(e) => setFilters({ ...filters, name: e.target.value })}
            className="p-2 border rounded"
          />
          <select
            value={filters.program}
            onChange={(e) => setFilters({ ...filters, program: e.target.value })}
            className="p-2 border rounded"
          >
            {programs.map((program) => (
              <option key={program} value={program}>
                {program}
              </option>
            ))}
          </select>
          <input
            type="number"
            placeholder="Min Overall"
            value={filters.minOverall}
            onChange={(e) =>
              setFilters({ ...filters, minOverall: parseInt(e.target.value, 10) })
            }
            className="p-2 border rounded"
          />
          <input
            type="number"
            placeholder="Max Overall"
            value={filters.maxOverall}
            onChange={(e) =>
              setFilters({ ...filters, maxOverall: parseInt(e.target.value, 10) })
            }
            className="p-2 border rounded"
          />
        </div>
        <button
          onClick={clearFilters}
          className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Clear Filters
        </button>
      </div>

      {/* Quick Filters */}
      <div className="bg-white shadow p-4 rounded-lg mb-4">
        <h2 className="text-lg font-bold mb-4">Quick Filters</h2>
        <div className="flex flex-wrap gap-2">
          <button
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            onClick={() => handleQuickFilter("Most Profitable")}
          >
            Most Profitable
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            onClick={() => handleQuickFilter("Highest Sales")}
          >
            Highest Sales
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            onClick={() => handleQuickFilter("Highest Overall")}
          >
            Highest Overall
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            onClick={() => handleQuickFilter("Highest Q2")}
          >
            Highest Q2
          </button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center py-8">
          <div className="h-8 w-8 text-blue-500 animate-spin">Loading...</div>
        </div>
      ) : filteredSets.length > 0 ? (
        <div className="space-y-8">
          {filteredSets.map((set) => (
            <div key={set.id} className="bg-white shadow p-4 rounded-lg space-y-4">
              <SetDetails set={set} />
              <SetsOnFire set={set} />
              <SetRequirements set={set} />
              <ProfitCalculation set={set} />
              <TargetBuyPrices set={set} />
              <WishlistVsSetsVsAuction set={set} />
              <StrategyAnalysis set={set} />
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-8 text-gray-500">No sets found</div>
      )}
    </div>
  );
};

export default MaddenAnalysis;
