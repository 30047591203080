import React from 'react';

const SetRequirements = ({ set }) => {
  const getSetPartsDetails = () => {
    const parts = [];
    const setNotes = set[`set_notes`];

    // Extract and split parts from set_notes
    const notesParts = setNotes
      ? setNotes.split('=').pop().trim().split('&').map((note) => note.trim())
      : [];

    for (let i = 1; i <= notesParts.length; i++) {
      const quantity = set[`part${i}_quantity`];
      const q2Subtotal = set[`part${i}_q2_subtotal`];
      const cardsUsed = set[`cards_used_q2${i > 1 ? `_pt${i}` : ''}`];
      const pricesUsed = set[`prices_used_q2${i > 1 ? `_pt${i}` : ''}`];

      if (quantity && quantity > 0) {
        const priceBreakdown = pricesUsed ? pricesUsed.join(' + ') : ''; // Create the math breakdown
        const subtotalMath = `${priceBreakdown} = ${q2Subtotal}`; // Append the subtotal

        parts.push(
          <div key={i} className="mb-6">
            {/* Display the extracted part note */}
            <div className="font-medium text-gray-700 mb-2">{notesParts[i - 1]}</div>

            {/* Example cards and their Q2 prices */}
            <div className="grid grid-cols-2 gap-4">
              {cardsUsed &&
                pricesUsed &&
                cardsUsed.map((card, index) => (
                  <div key={index} className="flex justify-between border-b py-1">
                    <span>{card || 'Unknown Card'}</span>
                    <span>{pricesUsed[index]?.toLocaleString() || '0'} coins</span>
                  </div>
                ))}
            </div>

            {/* Subtotal breakdown */}
            <div className="mt-2 font-medium text-right text-sm text-gray-700">
              {subtotalMath}
            </div>
          </div>
        );
      }
    }
    return parts;
  };

  return (
    <div className="bg-gray-50 p-4 rounded-lg mb-4">
      <div className="font-semibold text-lg mb-2">Set Requirements & Example Q2 Prices:</div>
      <div className="text-sm text-gray-600 mb-4">
        Note: These are example cards our system found at good prices. You can use ANY cards of the required OVR/program - just aim for the target prices shown below.
      </div>

      {/* Render all parts dynamically */}
      {getSetPartsDetails()}

      {/* Display total set cost */}
      <div className="text-right font-bold pt-4 border-t">
        Total Set Cost: {parseInt(set.set_total_q2).toLocaleString()} coins
      </div>
    </div>
  );
};

export default SetRequirements;
