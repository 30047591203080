import React from 'react';
import { Flame } from 'lucide-react';

const SetsOnFire = ({ set }) => {
  const setQ2Cost = parseInt(set.set_total_q2 || 0);
  const setCheapestBuyoutCost = parseInt(set.set_total_cheapest || 0);
  const q2Price = parseInt(set.q2_price || 0);
  const cheapestBuyout = parseInt(set.cheapest_buyout_price || 0);
  const q3Price = parseInt(set.q3_price || 0);

  // Determine Flame Level and Explanation
  const determineFlameLevel = () => {
    if (q3Price > q2Price && q3Price - setQ2Cost > q2Price * 0.2) {
      return {
        flameLevel: 6,
        explanation:
          'This set has high potential for profit, with market trends suggesting Q3 pricing may exceed expectations.',
      };
    }
    if (q2Price - setQ2Cost > q2Price * 0.1 && setQ2Cost < cheapestBuyout) {
      return {
        flameLevel: 5,
        explanation:
          'This set is profitable and also cheaper than the current cheapest buyout.',
      };
    }
    if (q2Price - setQ2Cost > q2Price * 0.1) {
      return {
        flameLevel: 4,
        explanation: 'This set is profitable compared to its Q2 price.',
      };
    }
    if (
      setCheapestBuyoutCost < setQ2Cost &&
      setQ2Cost < q2Price &&
      setQ2Cost < cheapestBuyout
    ) {
      return {
        flameLevel: 3,
        explanation:
          'The cost to build this set with the cheapest buyout cards is less than the Q2 set cost, which in turn is less than both the Q2 price and current cheapest buyout price.',
      };
    }
    if (setQ2Cost < q2Price && setQ2Cost < cheapestBuyout) {
      return {
        flameLevel: 2,
        explanation:
          'This set, on average, is less than the average price of the card & the current cheapest buyout price.',
      };
    }
    if (setQ2Cost < q2Price) {
      return {
        flameLevel: 1,
        explanation: 'This set, on average, is less than the average price of the card.',
      };
    }
    return { flameLevel: 0, explanation: 'This set does not currently meet any fire rating criteria.' };
  };

  const { flameLevel, explanation } = determineFlameLevel();

  return (
    <div className="bg-gray-50 p-4 rounded-lg">
      <div className="font-semibold text-lg mb-2">Sets on Fire</div>
      <div className="flex items-center space-x-2 mb-2">
        {/* Render flames dynamically based on flame level */}
        {Array.from({ length: flameLevel }, (_, i) => (
          <Flame
            key={i}
            className={`w-6 h-6 ${i < 3 ? 'text-orange-500' : 'text-purple-500'}`}
          />
        ))}
      </div>
      {/* Explanation for the current flame level */}
      <p className="text-sm text-gray-600">{explanation}</p>
    </div>
  );
};

export default SetsOnFire;

